@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;1,400&display=swap");
@font-face {
  font-family: "Isidora Sans";
  src: url("/public/Latinotype - Isidora Sans Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Isidora Sans";
  src: url("/public/Latinotype - Isidora Sans Light.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Isidora Sans";
  src: url("/public/Latinotype - Isidora Sans SemiBold.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Isidora Sans";
  src: url("/public/Latinotype - Isidora Sans Bold.otf");
  font-weight: 700;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}

.slick-dots li.slick-active div {
  background: #000000 !important; 
  opacity: 1 !important;
}

/* General Slick arrow customization */
.slick-arrow {
  font-size: 30px; /* Default desktop arrow size */
  color: black;    /* Default desktop arrow color */
  z-index: 1;
  cursor: pointer;
  opacity: 0.75;
  transition: opacity 0.3s ease;
}

.slick-arrow:hover {
  opacity: 1;
}

/* Custom positioning for next arrow */
.slick-next {
  right: 25px;
}

/* Custom positioning for prev arrow */
.slick-prev {
  left: 25px;
}

/* Ensure default slick arrows are hidden */
.slick-next:before, .slick-prev:before {
  content: none !important; /* Prevent default slick arrows from rendering */
}

/* Mobile version: smaller white arrows */
@media (max-width: 768px) {
  .slick-arrow {
    font-size: 20px !important; /* Smaller arrow size for mobile */
    color: white !important;    /* White arrow color for mobile */
    opacity: 1 !important;      /* Fully opaque on mobile */
  }

  .slick-next {
    right: 15px !important; /* Adjust positioning for smaller screens */
  }

  .slick-prev {
    left: 15px !important;  /* Adjust positioning for smaller screens */
  }
}
